<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <ul class="bg-bubbles">
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" xl="2">
          <v-card elevation="12">
            <LoaderThreeBody
              absolute
              :opacity="0.2"
              :loading="loading"
              color="#ffffff"
            />
            <v-row>
              <v-col cols="12" md="12">
                <v-card-text class="mt-4">
                  <v-card flat class="d-flex justify-center">
                    <v-img
                      src="@/assets/images/titan2.png"
                      lazy-src="https://picsum.photos/10/6?image=10"
                      max-height="200"
                      max-width="200"
                    />
                  </v-card>
                  <v-form
                    v-model="validSignIn"
                    ref="formSignIn"
                    class="pl-12 pr-12"
                  >
                    <v-text-field
                      v-model="form.email"
                      :label="$t('email')"
                      name="Email"
                      prepend-icon="mdi-email"
                      type="text"
                      :rules="[isRequired]"
                      @keyup.enter="login"
                    />
                    <v-text-field
                      v-model="form.password"
                      id="password"
                      :label="$t('password')"
                      name="password"
                      prepend-icon="mdi-lock"
                      :rules="[isRequired]"
                      @keyup.enter="login"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    />
                  </v-form>
                </v-card-text>
                <div class="text-center my-3 mx-8">
                  <v-btn block color="#00668D" dark @click="login">
                    Ingresar
                  </v-btn>
                </div>
                <div class="d-flex justify-end mr-8">
                  <v-btn text x-small color="#00668D" disabled>
                    Crear cuenta
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      validSignIn: false,
      validSignUp: false,

      //rules
      isRequired: (value) => !!value || "Value is required",
      form: {
        email: "",
        password: "",
      },
      loading: false,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions({
      signIn: "signIn",
    }),
    async login() {
      if (this.$refs.formSignIn.validate()) {
        this.loading = true;
        await this.signIn(this.form)
          .then(response => {
            this.loading = false;
            this.$router.push({
              name: "dashboard",
            });
            this.$notify({
              group: 'petition',
              title: 'Horus',
              type: 'success',
              text: `Bienvenido ${response.data.user.name}`,
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$notify({
              group: 'petition',
              title: 'Horus',
              type: 'error',
              text: `Credenciales Incorrectas: ${error}`,
            });
          });
      }
    },
    async signUp() {
      if (this.$refs.formSignUp.validate()) {
        this.$router.push({
          name: "dashboard",
        });
      } else {
        console.log("No ingresa 2");
      }
    },
  },
};
</script>

<style scoped>
.login__transparent {
  opacity: 0.7;
}
</style>